.user-photo {
  width: 100px;
  height: 100px;
  // overflow: hidden;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  padding: 0.5rem;
  position: relative;
  img {
    max-height: 80px;
      max-width: 80px;
    // border-radius: 50%;
  }

  input {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      z-index: -1;
  }
  label {
      position: absolute;
  bottom: 10px;
  right: -3px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #007b91;
  border: 1px solid #81CFE2;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  cursor: pointer;
  }

  @include breakpoint(phone) {
    width: 60px;
    height: 60px;
  }
}

.user-info {
  display: flex;
  padding: 1rem;
  background-color: #EDF4F4;
  border-radius: 8px;  
  .user-details {
    h4 {
        color: #007B91;
        font-weight: 400;
    }
    p {
      margin: 0;
      color: #334A51;
      font-size: 0.875rem;
      margin: 0 0 0.25rem;
      font-weight: 300;
    }
  }
}
