@mixin breakpoint($breakpoint) {
    @if $breakpoint==small-phone {
      @media (max-width: 380px) {
        @content;
      }
    }
  
    @if $breakpoint==phone {
      @media (max-width: 576px) {
        @content;
      }
    }
  
    @if $breakpoint==large-phone {
      @media (max-width: 678px) {
        @content;
      }
    }
    
    @if $breakpoint==tablet {
      @media (max-width: 768px) {
        @content;
      }
    }
  
    @if $breakpoint==tablet-large {
      @media (max-width: 991px) {
        @content;
      }
    }
  
    @if $breakpoint==tablet-land {
      @media (max-width: 1024px) {
        @content;
      }
    }
  
    @if $breakpoint==min-desktop {
      @media (max-width: 1199px) {
        @content;
      }
    }
  
    @if $breakpoint==apple-laptop {
      @media (max-width: 1280px) {
        @content;
      }
    }
  
    @if $breakpoint==desktop {
      @media (max-width: 1366px) {
        @content;
      }
    }
    @if $breakpoint==apple-desktop {
      @media (max-width: 1440px) {
        @content;
      }
    }
  
    @if $breakpoint==large-desktop {
      @media (max-width: 1600px) {
        @content;
      }
    }
  
    @if $breakpoint==large-then-desktop {
      @media (max-width: 1920px) {
        @content;
      }
    }
  }

