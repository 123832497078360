.institutions-container {
  .card {
    .card-body {
      padding: 2rem;
    }
  }
  @include breakpoint(tablet-large) {
    .card-body{
margin-top: 80px !important;
}
  }
  @include breakpoint(tablet) {
    display: contents;
    margin-top: 0;
    height: auto;
    padding: 1rem 1.5rem 0;
  }
}

.switches-container {
  width: 140px;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: #e7f1f3;
  line-height: 3rem;
  border-radius: 8px;
  margin-right: 1rem;

  input {
    // visibility: hidden;
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    // z-index: 5;
    opacity: 0;
    left: 0;

    &#switchCustomer {
      right: 0;
      left: auto;
    }
  }
  label {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: #778f97;
    font-size: 1.25rem;
    font-weight: 500;
  }
  .switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 0.3rem;
    z-index: 3;
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    /* transition: transform 1s; */
  }
  .switch {
    border-radius: 8px;
    background: #b5e3ee;
    height: 100%;
    font-weight: 500;

    div {
      width: 100%;    
      height: 100%;
      line-height: 2;
      text-align: center;
      opacity: 0;
      display: block;
      color: #007b91;
      transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
      will-change: opacity;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 1.25rem;
    }
  }
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container
  input:nth-of-type(1):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container
  input:nth-of-type(2):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(2) {
  opacity: 1;
}



.institution-logo {
  h4 {
    color: #007b91;
    font-size: 0.875rem;
    font-weight: 400;
    // text-align: center;
  }
  .user-photo {
    background-color: #f5f5f5;
    border: 1px solid #b5e3ee;
    // margin-right: auto;
    // margin-left: auto    
  }
}
