* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
   width: 6px;
   height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
   background: #e0e0e0;
   box-shadow: inset 0px 0px 3px #c0c0c0;
   border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: #888;
   border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: #797979;
}

:root ,html, body {
   font-size: 16px;

   @media  (max-width: 1440px) {
      font-size: 15px;        
  }

   @media  (max-width: 992px) {
      font-size: 14px;        
  }
   @media  (max-width: 768px) {
       font-size: 13px;        
   }
   @media  (max-width: 587px) {
      font-size: 12px;        
  }
}


body {
   font-size: 1rem;
   font-family: $poppins;
}

.main-container {
   width: 100%;
   height: 100vh;
   overflow-x: hidden;
   background-color: #edf2f2;
}

.dashboard-container {
   height: calc(100vh - 115px);
   background-color: #edf2f2;
   font-family: "Poppins", sans-serif;
   top: 80px;
   position: relative;
   overflow: hidden;
   overflow-y: auto;
   padding: 1rem 1.5rem 0 304px;
   @include breakpoint(desktop) {
      padding: 1rem 1.5rem 0 275px;
   }
   @include breakpoint(tablet-large) {
      padding: 1rem 1.5rem 0;
   }
}

.institutions-container {
   height: calc(100vh - 115px);
   padding: 1rem 1.5rem 0 436px;
   background-color: #edf2f2;
   font-family: "Poppins", sans-serif;
   top: 80px;
   position: relative;
   overflow-y: auto;
   overflow-x: hidden;
   @include breakpoint(tablet-large) {
      padding: 1rem 1.5rem 0 275px;
   }
}

h3 {
   color: $secoundary;
   font-size: 1.25rem;
}

h4 {
   font-size: 1rem;
   color: $secoundary;
}

.verical-space-between {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   min-height: calc(100vh - 245px);
   padding-bottom: 0;
}

.filter {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   margin-bottom: 1rem;
}

// Error msg
.error-msg {
   color: #eb3d3d;
   text-align: left;
   font-size: 0.9rem;
}

// Table style
.react-table {
   input[type="checkbox"] {
      accent-color: #216ba5;
      width: 18px;
      height: 18px;
   }

   .rdt_TableHeadRow {
      background-color: #778f97;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #ffffff;
      font-size: 1rem;

      input[type="checkbox"] {}
   }

   .rdt_TableRow {
      cursor: pointer;
      color: #334a51;
      font-weight: 300;
   }

   .rdt_TableBody {
      border: 1px solid #778f97;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
   }
}

.footer {
   position: fixed;
   z-index: 4;
   padding: 7px;
   padding-left: 305px;
   width: 100%;
   bottom: 0;
   background: #e1f0f1;

   p {
      color: #727171;
      margin-bottom: 0;
      font-size: 14px;
      text-align: center;
   }

   @include breakpoint(tablet-large) {
      padding-left: 15px;
   }
}

.loader {
   position: fixed;
   z-index: 1000;
   background-color: rgba(255, 255, 255, 0.8);
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   left: 0;
   top: 0;
}

.mapboxgl-canvas {
   position: static;
   height: 700px !important;
}

.mapboxgl-map,
.mapboxgl-canvas-container {
   height: 100% !important;
}

.login-form {
   .mapboxgl-canvas {
      height: 350px !important;
      margin-top: 2rem;
   }
}

.modal {
   .modal-dialog {
      margin-left: auto;
      margin-right: 0;
      // top: 50%;
      // transform: translateY(-50%);
   }

  .modal-title {
      text-align: center;
      color: #334A51;
      font-size: 1.35rem;
      font-family: 'Poppins', sans-serif;
      margin-top: 1.5rem;
   }
}