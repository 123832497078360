.sidebar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 280px;
  z-index: 9;
  .menu-close {
    background-color: #33333327;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  &--main {
    position: relative;
    background-color: #007b91;
    padding: 30px 20px 20px;
    height: calc(100vh - 80px);
    z-index: 11;
  }
  &--icon {
    font-size: 1.8rem;
  }

  &--content {
    margin-left: 12px;
  }

  &--arrow {
    font-size: 24px;
  }

  &.sidebar--small {
    width: 92px;
    min-width: auto;

    .sidebar--content,
    .sidebar--arrow {
      display: none;
    }
  }

  a {
    font-family: $poppins;
    color: #81cfe2;
    border-radius: 8px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: all 0.3s;

    &.active {
      color: #ffffff;
      background-color: #004a5e;
    }

    &:hover,
    &:focus {
      color: #ffffff;
    }

    div {
      display: flex;
      align-items: center;
    }
  }

  @include breakpoint(desktop) {
    width: 250px;
  }

  @include breakpoint(tablet-large) {
    width: 100%;
    opacity: 1;
    left: -300px;
    z-index: -99;
    transition: all 0.4s ease;

    &--main {
      width: 250px;
    }
  }
}

// Institutions menu

.institutions-menu {
  position: fixed;
  background-color: #004a5e;
  width: 320px;
  height: calc(100vh - 80px);
  left: 92px;
  bottom: 0;
  z-index: 9;

  &--inner {
display: inline-block;
width: 100%;
  }
  &--header {
    font-size: 1.25rem;
    color: $primary;
    padding: 1.6rem;
    padding-bottom: 0.9rem;
  }

  &--search-main {
    padding: 1.6rem;
    padding-top: 0;
  }

  &--search {
    position: relative;
    margin-right: 1rem;

    input {
      padding: 6px;
      padding-left: 35px;
      background-color: #bcd2d8;
      border-radius: 4px;
      border: 1px solid $greenDark;
      &::-webkit-input-placeholder {
        font-size: 0.9rem;
        font-weight: $Light;
      }

      &:-ms-input-placeholder {
        font-size: 0.9rem;
        font-weight: $Light;
      }

      &::placeholder {
        font-size: 0.9rem;
        font-weight: $Light;
      }

      &:focus,
      &:hover {
        box-shadow: none;
        border: 1px solid $greenDark;
        background-color: #bcd2d8;
      }
    }

    .search-icon {
      position: absolute;
      top: 11px;
      left: 10px;
      color: #778f97;
    }
  }

  &--add {
    font-size: 1.6rem;
    padding: 5px;
    line-height: 1;
    background-color: #b5e3ee;
    color: #007b91;
    border: 1px solid $greenDark;

    &:focus,
    &:hover,
    &:active {
      box-shadow: none;
      border: 1px solid $greenDark !important;
      background-color: #d6eaee !important;
      color: $greenDark !important;
    }
  }
  &--accordian {
    height: calc(100vh - 226px);
    overflow: hidden;
    overflow-y: auto;
    ul {
      list-style-type: none;
      color: $primary;
      font-weight: $Light;
      margin-bottom: 0;
    }
  }

  .accordion-item {
    border: none;
    border-radius: 0;
    background-color: transparent;
    border-bottom: 1px solid #23606d;
    border: 0 none;
  }

  .accordion-button {
    background-color: $greenDark;
    color: $primary;
    padding: 0.8rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    box-shadow: none;
    font-weight: $Light;
    border-radius: 0 !important;

    &.collapsed {
      color: #81cfe2;
    }

    &:after {
      background-image: url(../../images/down-arw.png);
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
    }

    &:hover {
      // background-color: #165b6d;
    }
  }

  .accordion-body {
    padding: 0;
    ul {
      padding-left: 0;
      li {
        padding: 0.7rem;
        padding-left: 2.5rem;
        cursor: pointer;
        background-color: $greenDark;

        &:hover {
          background-color: #165b6d;
        }
      }
    }

    div {
      padding-left: 3rem;
      padding-right: 1.6rem;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      color: #81cfe2;
      cursor: pointer;
      &.active {
        color: #ffffff;
        background-color: #165b6d;
        &:hover {
          background-color: #165b6d;
        }
      }
      &:hover {
        background-color: #165b6d5c;
      }
    }
  }

  .accordion-collapse {
    border-radius: 0 !important;
    .nav {
      flex-direction: column;
      .nav-link {
        display: flex;
        padding-left: 3rem;
        padding-right: 1.6rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        color: #81cfe2;
        &.active {
          color: #ffffff;
          background-color: #165b6d;
          &:hover {
            background-color: #165b6d;
          }
        }
        &:hover {
          background-color: #165b6d5c;
        }
      }
    }
  }

  @include breakpoint(tablet-large) {
    position: fixed !important;
    left: 0;
    top: 0px;
    z-index: 8;
    width: 250px;
    display: none;
    &.show {
      display: block;
      background-color: #004a5e38;
      .fixed-mobile-shadow{
        position: absolute;
        width: 100%;
        height: 100%;
      z-index: 2;
      }
     .institutions-menu--inner {
      z-index: 3;
      position: relative;
      display: inline-block;
      max-width: 260px;
      height: 100%;
      background-color: #23606d;
      }
    }
  }

  @include breakpoint(tablet) {
    position: relative;
    margin-top: 80px;
    height: auto;
    width: 100%;
    &--accordian {
      height: auto;
    }
  }
}

.mobile-menu-show {
  opacity: 1;
  left: 0;
  z-index: 9;
}

.hamburger {
  position: fixed;
  z-index: 12;
  display: none;
  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #004a5e;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
  &.open {
    span {
      opacity: 1;
      transform: rotate(45deg) translate(-5px, -15px);
      // background: #232323;
      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(-5px, 15px);
      }
    }
  }

  @include breakpoint(tablet-large) {
    display: inline-block;
    top: 28px;
    left: 20px;
  }
}
