.login {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  justify-content: right;

  background: #e4f7f8;

  // background: url(../../images/login-bg.svg) left top no-repeat;
  // background-size: contain;
  .login-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e4f7f8;
    position: relative;

    .grameen-logo {
      width: 40%;
    }

    .leaf {
      position: absolute;
      width: 100%;
      height: 100%;

      .leaf-left {
        position: absolute;
        top: 18%;
        left: -5%;
        width: 32%;
      }

      .leaf-right-1 {
        position: absolute;
        top: 40%;
        right: -7%;
        width: 14%;
      }

      .leaf-right-2 {
        position: absolute;
        top: 57%;
        right: -9%;
        width: 30%;
        z-index: 2;
      }
    }
  }

  h1 {
    color: $secoundary;
    font-family: $poppins;
    font-weight: $SemiBold;
    font-size: 2.5rem;
  }

  p {
    color: $greenLight;
    font-family: $poppins;
    font-weight: $Regular;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }

  .form-floating {
    width: 90%;
    min-width: 435px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;

    & > label {
      // left: 30px !important;
    }
  }

  .login-form-main {
    background: #ffffff;
    border-radius: 5% 0 0 5%;
    z-index: 1;
  }

  .login-form {
    text-align: center;

    .form-control {
      border-radius: 8px;
      border: 1px solid #007b91;
      padding-left: 3rem;
    }

    .form-check {
      display: flex;

      .form-check-input {
        opacity: 0;
        z-index: -2;
        position: relative;
      }

      .form-check-label {
        font-weight: $Regular;
        color: #334a51;
      }
    }

    .remember-me {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1.5rem 0;
    }
  }

  @include breakpoint(desktop) {
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 0.9rem;
    }

    .login-form {
      .form-control {
        font-size: 0.9rem !important;
      }
    }
  }

  @include breakpoint(tablet) {
    display: inline-block;
    h1 {
      font-size: 1.7rem;
    }
    .login-form-main {
      padding-right: 0;
    }
    .login-form {
      .form-control {
        height: calc(3rem + 2px);
      }
    }
    .form-floating {
      min-width: 100%;
    }
    .login-img {
      min-height: 120px;
      .grameen-logo {
        max-width: 150px;
      }
      // display: none;
    }

    .login-form-main {
      background-color: #e4f7f8;
    }
  }

  @include breakpoint(phone) {
    .login-form {
      .form-control{
padding-top: 0px !important;
      }
    }
    h1{
      font-size: 1.5rem;
    }
  }
}
.forget-btn {
  font-size: 0.875rem;
  font-weight: $Regular;
  color: #007b91;
  &:hover {
    color: #006e83;
  }
}

@include breakpoint(tablet) {
  .form-floating {
    svg,
    img {
      font-size: 1.2rem;
    }
  }
}
