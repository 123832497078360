.header {
   z-index: 9;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   padding: 10px 20px;
   background-color: $headerBg !important;

   .navbar-brand {
      img {
         max-height: 48px;
      }
   }

   .thumbnail-image {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      object-fit: cover;
      overflow: hidden;
   }

   .user-dropdown {
      display: flex;
      align-items: center;

      svg {
         font-size: 24px;
         color: #007b91;
      }
   }

   .dropdown-toggle {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 6px 10px;
      display: flex;
      align-items: center;

      &:after {
         display: none;
      }
   }

   p {
      color: #007b91;
      font-size: 1rem;
      padding: 0rem 0.853rem;
      padding-right: 1.7rem;
      margin-bottom: 0;

      span {
         display: block;
         color: #778f97;
         font-size: 0.813rem;
      }
   }

   .notification {
      margin-right: 20px;
      border-radius: 50%;
      background: #ffffff;
      position: relative;
      height: 54px;
      width: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
         content: "";
         position: absolute;
         width: 8px;
         height: 8px;
         background-color: #ed680f;
         border-radius: 50%;
         top: 14px;
         right: 17px;
         box-shadow: 0px 3px 3px #747474;
      }

      svg {
         font-size: 24px;
         color: #007b91;
      }
   }

   .dropdown {
      &.show {
      }
   }

   .header-nav {
      .dropdown-menu {
         // width: 100%;
         margin-top: 14px;
         box-shadow: 0px 3px 10px #00000014;
         border: none;

         a {
            color: #334a51;
            padding: 10px;
            &:active {
               background-color: #e9ecef !important;
            }
            svg {
               margin: 0px 10px;
               font-size: 1.2rem;
               color: #007b91;
            }
         }
      }
   }

   @include breakpoint(tablet-large) {
      .navbar-brand {
         margin-left: 36px;
      }
   }

   @include breakpoint(phone) {
      .thumbnail-image {
         width: 35px;
         height: 35px;
      }
   }


}
