.dual-list-container {

   .course-dropdown{
      justify-content: end;
      .select-input{
         padding: 0 15px 0 25px;
      }
   }
   .dual-list {
      width: 100%;
      display: flex;
      margin: 0 auto;

      .new-keywords-list,.existing-keywords-list{
         width: 48%;
         height: auto;
      }
      .update-icon{
         width: auto;
         height: auto;
         align-items: center;
         display: grid;
         margin: 0 auto;
        font-size: 2rem;
        color: #007B91;
     svg{
      cursor: pointer;
     }


      }


      .card {
         .form-check {
            padding-left: 0;
         }
         .card-title {
            background-color: #778f97;
            border-radius: 4px 4px 0 0;
            margin: 0;
            padding: 0.5rem 1rem;
            .form-check {
               padding-top: 0.5rem;
               padding-left: 11px;
               .form-check-label {
                  color: #ffffff;
                  font-size: 1rem;
                  font-weight: 500;
                  &:before {
                     border: 2px solid #ffffff;
                  }
               }
               input:checked + label:before {
                  background-color: #007b91;
                  border: 2px solid #007b91;
               }
            }
         }
         .card-body {
            padding: 1rem 1.5rem;
            border: 1px solid #778f97;
            height: calc(100vh - 420px);
            overflow: auto;
            border-radius: 0 0 4px 4px;

            .mb-3 {
               display: flex;

               .close {
                  background-color: #778f97;
                  width: 21px;
                  height: 21px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                  left: 3px;
                  top: 0;
                  border-radius: 0 4px 4px 0;
                  font-size: 1rem;
                  cursor: pointer;
                  font-weight: normal;
                  color: #FFFFFF;
               }
            }

            .form-check-label {
               span {
                  background-color: #edf2f2;
                  display: inline-block;
                  padding: 0.25rem 0.5rem;
                  line-height: 1;
                  border-radius: 4px 0 0 4px;
                  font-size: 0.8125rem;
                  font-weight: 300;
                  
               }
            }
         }
      }
   }


   @include breakpoint(tablet) {
      .course-dropdown{
         .select-input{
            padding: 0 10px;
            // max-width: 400px;
         }
      }
   }
   @include breakpoint(phone) {
      .dual-list {
         flex-direction: column;
         .new-keywords-list, .existing-keywords-list{
            width: 100%;
         }
      }
   }
}

.geocode {
   .course-list {
      height: calc(100vh - 310px);
      overflow: auto;
      padding-right: 0.5rem;
   }
}
