.courses-list {
   .add-keywords {
      margin-top: 2.75rem;
      padding: 1.25rem;
      background-color: rgba(237, 242, 242, 0.5);
      border-radius: 8px;
      color: #334a51;

      label {
         font-weight: 500;
      }

      .chips-input {
         width: 100%;
         .MuiInputBase-root {
            border-radius: 8px;
            border: 1px solid #b5e3ee;
            // max-height: 113px;
            max-height: 500px;
            overflow: auto;
            background-color: #ffffff;

            .MuiOutlinedInput-notchedOutline {
               border-color: transparent;
            }

            &:hover {
               .MuiOutlinedInput-notchedOutline {
                  border-color: transparent;
               }
            }
         }
         .MuiButtonBase-root {
            background-color: #edf2f2;
            border-radius: 4px;
            color: #334a51;
         }
      }
   }

   .dropzone {
      padding: 4rem 2rem;
      border-radius: 8px;
      border: 2px dashed #bcd2d8;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      flex-direction: column;
      row-gap: 1rem;
      margin: 1rem 0 2rem;
      .icon {
         color: #bcd2d8;
         font-size: 2rem;
      }
      p {
         margin: 0;
         text-align: center;
         color: #334a51;
         font-size: 0.875rem;
         a {
            color: #007b91;
            text-decoration: underline;
         }
      }
      &:hover {
         background-color: #f4f4f4;
      }
   }

   // Upload
   .uploading-files {
      display: flex;
      column-gap: 1rem;
      color: #778f97;
      font-size: 0.75rem;
      font-weight: 300;
      .file-info {
         display: flex;
         flex-direction: column;
         row-gap: 0.25rem;
         width: 100%;
         .d-flex {
            justify-content: space-between;
         }
      }
      .custom-progress-bar {
         height: 8px;
         .progress-bar {
            background: transparent linear-gradient(90deg, #81cfe2 0%, #007b91 100%) 0% 0% no-repeat padding-box;
         }
      }
   }
}

// MCQs
.mcqs {
   .mcq-question {
      margin-bottom: 2rem;
   }
   .answer {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .form-check {
         width: 130px;
         padding-left: 0.5rem;
         flex: none;
         label {
            color: #778f97;
         }
      }
   }
   .mcq-list {
      padding: 1.25rem;
      background-color: rgba(237, 242, 242, 0.5);
      border-radius: 8px;
      color: #334a51;
      height: 100%;
      margin-top: 3rem;
      .list-item {
         font-size: 0.875rem;
         border-radius: 4px;
         background-color: #e7f1f3;
         padding: 0.5rem 0.75rem;
         margin: 0 0 0.75rem;
         transition: all 0.2s ease;
         cursor: pointer;
         position: relative;
         padding-right: 2rem;
         .icon {
            position: absolute;
            right: -0.5rem;
            opacity: 0;
            visibility: hidden;
            transition: all 0.2s ease;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.25rem;
            color: #007b91;
         }
         &:hover {
            background-color: #ffffff;
            .icon {
               opacity: 1;
               visibility: visible;
               right: 0.75rem;
            }
         }
      }
   }

   @include breakpoint(phone) {
      .answer {
         flex-direction: column;
         align-items: flex-start;
         margin-bottom: 20px;
         .form-check{
            margin-bottom: 10px;
         }
      }
      .mcq-list-main{
         padding: 0px;
      }
      .mcq-list{
         padding: 5px;
         .list-item{
            padding-right: 5px;
            .icon{
               position: absolute;
               top: 17px;
            }
         }
      }
   }
}

// Course list
.course-list {
   .list-item {
      border-radius: 8px;
      border: 1px solid #bcd2d8;
      padding: 1rem 1.125rem;
      color: #334a51;
      margin: 0 0 0.65rem;
      .course-details {
         span {
            color: #778f97;
            font-weight: 300;
            display: inline-block;
            padding-right: 1rem;
            margin-right: 1rem;
            border-right: 1px solid #334a51;
            line-height: 1;
            &:last-child {
               border: 0 none;
            }
         }
      }
   }
}
