// floating label

.form-floating {
  position: relative;

  & > label {
    color: #778f97;
  }

  svg,
  img {
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #007b91;
  }

  .form-control {
    border-radius: 8px;
    border: 1px solid #b5e3ee;
  }

  & > .form-control:-webkit-autofill ~ label,
  & > .form-control:not(:placeholder-shown) ~ label,
  & > .form-control:focus ~ label {
    color: #007b91;
    transform: scale(0.85) translateY(-2.7rem) translateX(-3rem) !important;
  }

  & > .form-control-plaintext:focus,
  & > .form-control-plaintext:not(:placeholder-shown),
  & > .form-control:focus,
  & > .form-control:not(:placeholder-shown) {
    border: 1px solid #b5e3ee !important;
  }

  // With icon
  &.with-icon {
    .form-control {
      padding-left: 3rem;
    }

    label {
      left: 30px !important;
    }

    & > .form-control:-webkit-autofill ~ label,
    & > .form-control:not(:placeholder-shown) ~ label,
    & > .form-control:focus ~ label {
      left: 0 !important;
    }
  }
}

//
.select-input {
  .select__input-container {
    height: 46px;
  }

  .select__control {
    box-shadow: none;
    border-radius: 8px;
    border: 1px solid #b5e3ee;

    &:hover,
    &:focus {
      border: 1px solid #b5e3ee;
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__indicator {
    color: $greenDark;
  }

  .select__option {
    &:hover,
    &:active {
      background-color: #e5faff;
    }
  }

  .select__option--is-selected {
    background-color: #007b91 !important;
    color: #ffffff !important;
  }

  .select__option--is-focused {
    background-color: #e5faff;
    color: #333333;
  }

  @include breakpoint(tablet) {
    .select__input-container {
      height: 32px;
    }
  }
}

.form-control {
  border: 1px solid #b5e3ee !important;
}

.with-icon {
  .select__control {
    padding-left: 35px;

    svg {
      position: absolute;
      left: -22px;
      font-size: 22px;
      color: #0a7a90;
    }

    .select__value-container--has-value {
      .select__placeholder {
        left: -40px;
      }
    }
  }

  .select__indicators {
    svg {
      position: relative;
      left: 0px;
    }
  }
}

.show-password {
  position: absolute;
  top: 50%;
  right: 50px;
}

.doughnut-chart-label {
  font-family: "Poppins", sans-serif;
  list-style-type: none;
  margin-top: 18px;

  li {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: #334a51;
    margin-top: 8px;

    span {
      display: block;
      font-size: 13px;
      color: #778f97;
    }

    &::before {
      content: "";
      content: "";
      position: absolute;
      left: -15px;
      top: 5px;
      width: 10px;
      height: 10px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      background-color: #0a7a90;
    }

    &:nth-child(1) {
      &::before {
        background-color: #12d65b;
      }
    }

    &:nth-child(2) {
      &::before {
        background-color: #f7d037;
      }
    }

    &:nth-child(3) {
      &::before {
        background-color: #26d1f3;
      }
    }

    @include breakpoint(phone) {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      span {
        margin-left: 10px;
        font-weight: 600;
        color: #007b91;
      }

    }
  }
}

.doughnut-chart-label-two {
  font-family: "Poppins", sans-serif;
  list-style-type: none;
  margin-top: 18px;

  li {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: #334a51;
    margin-top: 8px;

    span {
      display: block;
      font-size: 13px;
      color: #778f97;
    }

    &::before {
      content: "";
      content: "";
      position: absolute;
      left: -15px;
      top: 5px;
      width: 10px;
      height: 10px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      background-color: #0a7a90;
    }

    &:nth-child(1) {
      &::before {
        background-color: #ed680f;
      }
    }

    &:nth-child(2) {
      &::before {
        background-color: #f2a16a;
      }
    }
    @include breakpoint(phone) {
      display: flex;
      flex-wrap: wrap;
      span {
        margin-left: 10px;
        font-weight: 600;
        color: #007b91;
      }
    }
  }
}

.dashboard {
  .basic-single {
    margin-top: 0px !important;
  }
}

.table-rank {
  position: relative;
  padding: 8px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  background-color: #e4f7f8;
  margin-right: 20px;

  &::before {
    position: absolute;
    content: "";
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid transparent;
  }

  &.increase {
    &::before {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #54d599;
    }
  }

  &.decrease {
    &::before {
      border-top: 6px solid #fa6363;
    }
  }
}

.card canvas {
  margin: 0px auto;
  margin-bottom: 25px;
}

.doughnut-chart {
  max-width: 50%;

  @include breakpoint(phone) {
    canvas {
      margin-bottom: 0px;
    }
  }
}

.doughnut-chart-main {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 15px;

  @include breakpoint(desktop) {
    flex-direction: column;
  }
}

.doughnut-chart canvas {
  max-width: 100%;
  height: auto !important;
  width: 220px !important;
}

.doughnut-chart-2 canvas {
  max-width: 100%;
  height: auto !important;
  width: 190px !important;
}

.line-chart,
.bar-chart {
  canvas {
    width: 100% !important;
    height: auto;
    padding: 15px;
    border-radius: 4px;
    background: #f6f8f8;
    margin-top: 20px !important;
  }
}

.cart-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .select-input .select__input-container {
    height: 25px;
  }
}

.chart-header {
  font-size: 16px;
  font-weight: 400;
  color: #334a51;
}

.react-datepicker-wrapper input {
  box-shadow: none;
  border-radius: 8px;
  height: 38px;
  padding: 10px;
  font-size: 16px;
  color: #334a51;
  border: 1px solid #b5e3ee !important;
  max-width: 200px;
  padding-left: 50px;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid #b5e3ee !important;
  }
}

.customer-date-picker {
  .react-datepicker-wrapper {
    input {
      padding-left: 10px;
      height: 56px;
      max-width: 100%;
    }
  }
}

.react-datepicker-popper[data-placement^="top"] {
  .react-datepicker__triangle {
    transform: translate(123px, 0px) !important;
    -webkit-transform: translate(123px, 0px) !important;
    -moz-transform: translate(123px, 0px) !important;
    -ms-transform: translate(123px, 0px) !important;
    -o-transform: translate(123px, 0px) !important;
  }
}

.react-datepicker__header {
  background-color: #0a7a90cc !important;
}

.react-datepicker__navigation {
  top: 8px !important;
}

.csv-image {
  img {
    max-width: 180px;
    max-height: 150px;
  }
}

.chart-main {
  display: flex;
  flex-wrap: wrap;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: initial;

  .chart-card {
    width: 50%;
  }

  @include breakpoint(phone) {
    .chart-card {
      width: 100%;
    }
    .doughnut-chart-main {
      padding-left: 0;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.chart-filter {
  display: flex;

  @media screen and (max-width: 420px) {
    flex-direction: column;
    align-items: flex-start;
    .dashboard-switch {
      margin-bottom: 10px;
    }
  }
}
.dashboard-switch {
  line-height: 2.5rem !important;
  background-color: #f9f9f9;
  margin-right: 1.6rem !important;
  label,
  .switch div {
    font-size: 0.8rem !important;
  }

  .switch-wrapper {
    padding: 0 !important;
  }

  .switch {
    width: 100%;
    box-shadow: 0px 0px 2px #00000029;
    div {
      line-height: 3;
      height: 100%;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}

.date-from-to-main {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  .react-datepicker-wrapper {
    input {
      width: auto;
      font-size: 0.85rem;
      max-width: 100px;
      padding: 0;
      text-align: center;
      border: 0px !important;
      background-color: transparent;
    }
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: #ffffff;
  }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    &:before,
    &:after {
      border-bottom-color: #0a7a90cc;
    }
  }
}

.bar-chart canvas {
  background-color: #ffffff;
}

.institution-select {
  max-width: 400px;
  .select__input-container {
    height: 30px;
  }
}

.count-list-cord {
  border: 1px solid #e2eef0;
  .cord-header {
    padding: 15px;
    p {
      color: #7e7e7e;
      margin-bottom: 0;
    }
  }
  .cord-body {
    padding: 15px;
    border-top: 1px solid #e2eef0;
    display: flex;
    p {
      flex-grow: 1;
      text-align: center;
      font-size: 1.3rem;
      font-weight: 600;
      margin-bottom: 0;
      color: #334a51;

      span {
        display: block;
        color: #778f97;
        font-size: 60%;
        font-weight: 400;
        text-transform: capitalize;
      }

      &:nth-child(2) {
        border-left: 1px solid #e2eef0;
      }
    }
  }
}

.crud-check-main {
  padding-top: 10px;

  @include breakpoint(tablet) {
    .form-check {
      padding-left: 7px;
      margin-top: 5px;
    }
  }
}
.crud-head{

  @include breakpoint(tablet) {
      color: #007b91;
      font-weight: 600;
  }

}
.crud-menu-head {
  @include breakpoint(tablet) {
    display: none;
  }
}
.crud-check-head {
  display: none;
  @include breakpoint(tablet) {
    display: block;
    font-size: 0.9rem;
  }
}

.add-keywords-file-upload{
  @include breakpoint(phone) {
    flex-direction: column;
  }
}

.add-institution, officers-list{
  @include breakpoint(tablet) {
    .institution-logo{
      margin-top: 2rem;
    }
    .basic-single, .form-floating{
      margin-top: 2.5rem !important;
    }
  }

}

@include breakpoint(tablet) {
.select__placeholder{
  font-size: 0.9rem !important;
}
}
@include breakpoint(tablet-large) {
.add-institution-btn{
  display: flex;
  align-items: center;
  justify-content: center;
    position: fixed;
    line-height: 2;
    width: 50px;
    height: 50px;
    z-index: 10;
    bottom: 1.2em;
    right: 0.7em;
    font-size: 2.5rem;
    color: #fff;
    background: linear-gradient(135deg, #004a5e, #195e70, #81cfe2);
    border-radius: 50%;
    cursor: pointer;
    box-shadow:0.1em 0 0.25em #54535359, 0.2em 0 1em #36363659;
    border: 1px solid #004a5e;
}
}