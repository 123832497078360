.login-btn {
  font-size: 1rem;
  padding: 13px 25px;
  color: $primary;
  background-color: $green;
  border: 1px solid $green;
  border-radius: 8px;

  &:hover,
  &:focus,
  &:active,
  &:disabled {
    background-color: #004a5e !important;
    border: 1px solid #004a5e !important;
  }

  @include breakpoint(tablet) {
      padding: 9px 25px;
  }
}

.btn-link {
  text-decoration: none;
}

.primary-btn {
  font-size: 1rem;
  padding: 13px 25px;
  border-radius: 8px;
  color: $primary;
  background-color: $green;
  border: 1px solid $primary;
  transition: all 0.3s ease;

  &:hover,
  &:focus,
  &:active,
  &:disabled {
    background-color: $greenDark !important;
    border: 1px solid $greenDark !important;
  }
}

.secondary-btn {
  font-size: 1rem;
  padding: 13px 25px;
  border-radius: 8px;
  color: $green;
  background: $primary;
  border: 1px solid $green;
  transition: all 0.3s ease;

  &:hover,
  &:focus,
  &:active {
    color: $greenDark !important;
    background-color: $primary !important;
    border: 1px solid $greenDark !important;
  }
}

.filter {
  .primary-btn {
    font-weight: $Regular;
    display: flex;
    align-items: center;
    font-size: 1rem;

    svg {
      font-size: 1.5rem;
      margin-right: 5px;
    }
  }

  .add-btn {
    font-size: 1.5rem;
    padding: 7px 15px;
    border-radius: 8px;
    color: #007b91;
    background: #b5e3ee;
    border: 1px solid #b5e3ee;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus,
    &:active {
      background: $greenDark;
      color: $primary;
      border: 1px solid $greenDark;
    }
  }
}
