.permission-form {
    .label {
        span {
            color: #334A51;
            font-weight: 500;
        }
    }
    .crud {
        div {
            color: #334A51;
            font-weight: 500;
        }
    }
}