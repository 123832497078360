.form-control {
  font-size: 1rem !important;
  font-family: $poppins;
}

//======= Input Floting label Start ==========//

.form-floating {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 0.25rem !important;
}

.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 10px !important;
  outline: none;
  box-shadow: none;
  border: 1px solid #000000;
}

.form-floating > .form-control:-webkit-autofill ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control:focus ~ label {
  transform: scale(0.85) translateY(-2.7rem) translateX(-1rem) !important;
  opacity: 1 !important;
}

.form-floating > label {
  padding: 1rem 1rem !important;
  font-family: $poppins;
}

//======= Input Floting label End ==========//

.form-check {
  label {
    position: relative;
    cursor: pointer;
    font-size: 0.875rem;

    &:before {
      content: "";
      appearance: none;
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #778f97;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 7px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 2px;
      margin-right: 8px;
      transition: all 0.4s ease;
      top: -1px;
    }

    &:after {
      transition: all 0.6s ease;
    }
  }

  input {
    &:checked + label {
      &:before {
        background-color: #007b91;
        border: 2px solid #007b91;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 7px;
        width: 5px;
        height: 13px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}

.form-check {
  display: flex;

  .form-check-input {
    opacity: 0;
    z-index: -2;
    position: relative;
  }

  .form-check-label {
    font-weight: $Regular;
    color: #334a51;
  }
}

.form-control {
  box-shadow: none;
  padding: 13px 16px;
  border: 1px solid #778f97;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    border: 1px solid #778f97;
  }
}

.icon-text-input {
  position: relative;

  .form-control {
    padding: 13px 16px;
    padding-left: 50px;
    color: #778f97;

    &:-ms-input-placeholder {
      color: #778f97;
    }

    &::placeholder {
      color: #778f97;
    }
  }

  svg {
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    color: $green;
    left: 18px;
    font-size: 1.4rem;
  }
}

.institution-list {
  &--search {
    position: relative;
    margin-right: 1rem;

    input {
      padding: 12px;
      padding-left: 45px;
      background-color: #e7f1f3;
      border-radius: 8px;
      border: 1px solid #e7f1f3;
      font-size: 0.9rem;
      min-width: 350px;

      &::-webkit-input-placeholder {
        font-size: 1rem;
        font-weight: $Light;
      }

      &:-ms-input-placeholder {
        font-size: 1rem;
        font-weight: $Light;
      }

      &::placeholder {
        font-size: 1rem;
        font-weight: $Light;
      }

      &:focus,
      &:hover {
        box-shadow: none;
        border: 1px solid $greenDark;
        background-color: #e7f1f3;
      }

    }

    .search-icon {
      position: absolute;
      top: 13px;
      left: 15px;
      color: #778f97;
      font-size: 1.3rem;
    }

    @include breakpoint(tablet) {
     margin-bottom: 1rem;
     width: 100%;
input{
  width: 100%;
  min-width: unset;
}
     
    }
  }
}

.basic-single {
  margin-top: 3rem !important;
}

@include breakpoint(tablet) {
  .form-check input:checked + label:after {
    top: 1px;
    left: 5px;
    width: 6px;
    height: 10px;
  }
  .form-check label:before {
    padding: 6px;
  }
}
