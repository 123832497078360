.card {
  background-color: #ffffff;
  border-radius: 8px;
  border: 0 none;
  min-height: 100%;
  .card-title {
    color: #334a51;
    margin: 0 0 1.25rem;
  }
  
  .card-body {
    padding: 2rem;

    @include breakpoint(phone) {
      padding: 2rem 1rem;
    }
  }

  &.officers-list {
    .card-title {
      display: flex;
      justify-content: space-between;

      .dropdown {
        .dropdown-toggle {
          padding: 0;
          color: #007b91;
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem;
          &:after {
            display: none;
          }
          .cross {
            display: none;
          }
        }
        .dropdown-menu {
          min-width: 245px;
          border: 0 none;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 8px;

          .dropdown-item {
            display: flex;
            column-gap: 1rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            &:focus, &:hover, &:active {
              background-color: #e9ecef !important;
              color: #1e2125 !important;
            }

            .icon {
              color: #007b91;
              font-size: 1.25rem;
              line-height: 1;
            }
            .content {
              h6 {
                color: #778f97;
                font-weight: 300;
                font-size: 0.8125rem;
                margin-bottom: 0.25rem;
              }
              p {
                margin: 0;
                line-height: 1.5;
                font-weight: 300;
                font-size: 0.8125rem;
              }
            }
          }
        }

        &.show {
          .dropdown-toggle {
            .dots {
              display: none;
            }
            .cross {
              display: block;
            }
          }
        }
      }
    }
  }
}

.right-answer-p{
  min-width: 125px;
  margin-bottom: 4px;
}