@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

$poppins: 'Poppins', sans-serif;

$Light: 300;
$Regular: 400;
$Medium: 500;
$SemiBold: 600;
$Bold: 700;
$Black: 900;

